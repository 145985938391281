import { Box, Button, Stack, Typography } from "@mui/material";
import bannerImg from "../../assets/banner1.png";
import { useNavigate } from "react-router-dom";

export const Banner1 = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: "linear-gradient(to right, #177694, #9c3)",
        fontSize: "50px",
        fontWeight: "bold",
        height: "350px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "text.secondary",
      }}
    >
      <Stack direction={"row"} p={2} width={"100%"}>
        <Stack flex={1} direction={"column"} justifyContent={"space-between"}>
          <Typography fontSize={{ xs: "1.2rem", md: "2rem" }} color={"white"}>
            Discover certified tutors near you, explore reviews, compare
            pricing, and schedule your session.
          </Typography>
          <Button
            onClick={() => navigate("/about")}
            variant="contained"
            sx={{ width: "max-content", border: "1px solid", mb: 2.5 }}
          >
            Read More
          </Button>
        </Stack>
        <Stack width={{ xs: "150px", sm: "200px", md: "200px" }}>
          <img src={bannerImg} width={"100%"} height={"100%"} />
        </Stack>
      </Stack>
    </Box>
  );
};
